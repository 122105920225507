/* - BASE HTML TEMPLATE
------------------------------------------------- 
	Description: Grid
	Author: Shane Prendergast
	Author URL: http://www.webknit.co.uk
	Template URL: http://base.webknit.co.uk/
	Notes:	
		My examples are just basic grids.
		I'm using Susy.
		Documentation: 
			http://susy.oddbird.net/demos/
			http://susydocs.oddbird.net/en/latest/install/
			http://www.chrismaxwell.com/setting-up-susy
			
		To use Susy you need to have the gems installed. Have a read of the following post for more info.
		http://shaneprendergast.co.uk/css/a-guide-to-base/	
*/


/* - CONTENTS
-------------------------------------------------
	- Susy ................................. 1.0.
	- Grids ................................ 2.0.
    	- Small/Default .................... 2.1.
    	- Medium ........................... 2.2.
    	- Large ............................ 2.3.
*/


/*********************************************
	- 1.0 -  SUSY
*********************************************/

	@import "susy/susy";

	$susy: (
	  gutters: 0
	);


/*********************************************
	- 2.0 -  GRIDS
*********************************************/

	/* - 2.1. - SMALL/DEFAULT
	------------------------------------------ */
	
	.one_twelve, .two_twelve, .three_twelve, .four_twelve,
	.five_twelve, .six_twelve, .seven_twelve, .eight_twelve,
	.nine_twelve, .ten_twelve, .eleven_twelve, .twelve_twelve {
		margin: 0 0 0em 0;
		padding: 2.5em 2.5em;
		@include span(12 of 12);
		@include breakpoint(large) {
			flex: 1;
		}
	}

	.row--content {
		.cat-block {
			@include breakpoint(large) {
				flex: 1;
			}
		}
	}


	/* - 2.2. - MEDIUM BREAKPOINT
	------------------------------------------ */

	@include breakpoint(medium) {
		.one_twelve, .two_twelve, .three_twelve, .four_twelve,
		.five_twelve, .six_twelve, .seven_twelve, .eight_twelve,
		.nine_twelve, .ten_twelve, .eleven_twelve, .twelve_twelve {
			padding: 5em 5em;
		}
		.one_twelve {
			@include span(1 of 12);
		}
		
		.two_twelve {
			@include span(2 of 12);
		}
		
		.three_twelve {
			@include span(3 of 12);
		}
		
		.four_twelve {
			@include span(4 of 12);
		}
		
		.five_twelve {
			@include span(5 of 12);
		}
		
		.six_twelve {
			@include span(6 of 12);
		}
		
		.seven_twelve {
			@include span(7 of 12);
		}
		
		.eight_twelve {
			@include span(8 of 12);
		}
		
		.nine_twelve {
			@include span(9 of 12);
		}
		
		.ten_twelve {
			@include span(10 of 12);
		}
		
		.eleven_twelve {
			@include span(11 of 12);
		}
		
		.twelve_twelve {
			@include span(12 of 12);
		}
		
		.end-grid {
			@include last;
		}
	}


	/* - 2.3. - LARGE BREAKPOINT
	------------------------------------------ */

	@include breakpoint(large) {
		
	}
