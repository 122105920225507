/* - BASE HTML TEMPLATE
------------------------------------------------- 
	Description: Base elements
	Author: Shane Prendergast
	Author URL: http://www.webknit.co.uk
	Template URL: http://base.webknit.co.uk/
*/


/* - CONTENTS
-------------------------------------------------
	- Structure ............................ 1.0.
	- Base Elements ........................ 2.0.
	- Links ................................ 3.0.
		- Base link styles ................. 3.1.
    	- Header links ..................... 3.2.
	- Build Elements ....................... 3.0.
*/


/*********************************************
	- 1.0 -  STRUCTURE
*********************************************/

	*, *:before, *:after { 
	  	box-sizing: border-box;
	}

	html { 
		font-size: 100%; 
		text-size-adjust: 100%; 
	}
	
	body {
		color: $col-standard;
		font-family: $base-font-family;
		font-size: $base-font-size;
		line-height: $base-line-height;
		margin: 0;	
	}

	html, body {
		height: 100%;
		width: 100%;
	}
	
	.container {
	    margin: 0 auto;
	    width: 96%;
	    @include breakpoint(large) {
			max-width: 73.125em;
			margin: 0 auto;
			width: 100%;
		}
	}
	
	.row {
		overflow: hidden;
		&--content {
			@include breakpoint(medium) {
				display: flex;
			}
		}
  	}


/*********************************************
	- 2.0 -  BASE ELEMENTS
*********************************************/

	h1, h2, h3, h4, h5, h6,
	.h1, .h2, .h3, .h4, .h5, .h6 {
		color: $col-header;
		font-family: $header-font-family;
		font-weight: $weight-header;
		letter-spacing: 0.1em;
		margin: 0 0 1em;
		text-transform: uppercase;
	}
	
	h1, .h1 {
		font-size: 2em;
	}
	
	h2, .h2 {
		font-size: 1.75em;
	}
	
	h3, .h3 {
		font-size: 1.5em;
	}
	
	h4, .h4 {
		font-size: 1.4em;
	}
	
	h5, .h5 {
		font-size: 1.3em;
	}
	
	h6, .h6 {
		font-size: 1.2em;
	}

	.sub-title {
		font-size: 0.9em;
	}
	
	small {
		font-size: 0.85em;
	}
	
	ul li {
		list-style: none;
	}
	
	ol li {
		list-style: none;
	}

	li {
		font-size: 1rem;
		@include breakpoint(medium) {
			font-size: 1.3rem;
		}
	}
	
	strong, b {
		font-family: $header-font-family;
		font-weight: $weight-header;
	}
	
	em, i {
		font-style: italic;
	}
	
	p {
		font-size: 1em;
		margin-bottom: 1em;
		@include breakpoint(medium) {
			font-size: 1.1em;
		}
	}
	
	img, object, embed {
		height: auto;
		max-width: 100%;
	}	
	
	blockquote {
		background: $col-blockquote;
		color: $col-white;
		font-size: 1.1em;
		font-style: italic;
		margin: 0 0 1.250em 1.250em;
		padding: 0.625em 1.250em 1.250em;
		p {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	
	
/*********************************************
	- 2.0 -  LINKS
*********************************************/

	/* - 2.1. - BASE LINK STYLES
	------------------------------------------ */

	a {
		color: $col-mine-shaft;
		cursor: pointer;
		text-decoration:none;
		&:visited {

		}
		&:hover {
			color: $col-link-hover;
		}
		&:active {
			
		}
		&:focus {
			
		}
		/* Improve readability when focused and hovered in all browsers: h5bp.com/h */
		&:focus,
		&:active {
			outline: none;
		}
	}
	
	
	/* - 2.2. - HEADER LINKS 
	------------------------------------------ */
	
	h1 a, h2 a, h3 a, h4 a, h5 a, h6 a,
	.h1 a, .h2 a, .h3 a, .h4 a, .h5 a, .h6 a {
		color: $col-header;
	}


/*********************************************
	- 3.0 -  BUILD ELEMENTS
*********************************************/

	.hide {
		display: none;
	}
	
	.center-align {
		text-align: center;
	}

	.border-right { 
		border-right: 1px solid $col-border;
	}
	
	.border-left { 
		border-left: 1px solid $col-border;
	}

	.border-bottom { 
		border-bottom: 1px solid $col-border;
	}

	.border-top { 
		border-top: 1px solid $col-border;
	}

