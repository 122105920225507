/* - BASE HTML TEMPLATE
------------------------------------------------- 
	Description: Base/Main CSS styles
	Author: Shane Prendergast
	Author URL: http://www.webknit.co.uk
	Template URL: http://base.webknit.co.uk/
	Notes: 
		Base CSS styles and Mobile first approach. 
*/


/* - CONTENTS
-------------------------------------------------
	- SASS modules ......................... 1.0.
    	- Variables ........................ 1.1.
    	- Functions .........................1.2.
    	- Mixins ............................1.3.
    - CSS Reset ............................ 2.0.
    	- Main reset ........................2.1.
    - SASS partials ........................ 3.0.
    	- Vendor ............................3.1.
    	- Base ............................. 3.2.
    	- Buttons ...........................3.3.
    	- Forms ............................ 3.4.
    - Grids ................................ 4.0.
    - Entry section ...................... 5.0.
    - Nav ...................... 6.0.
    - Single text ...................... 7.0.
    - Quick links ...................... 8.0.
    - Latest ...................... 9.0.
    - Charity  ...................... 10.0.
    - Footer  ...................... 11.0.
    - Header  ...................... 12.0.
    - Banner  ...................... 13.0.
    - Page content  ...................... 14.0.
    - Sidebar  ...................... 15.0.
    - Cat  ...................... 16.0.
    - Promotions  ...................... 17.0.
    - @font-face ........................... 0.0.
*/


/*********************************************
	- 1.0 -  SASS MODULES
	The modules directory is reserved for Sass code that doesn't cause Sass to actually output CSS. 
	Things like mixin declarations, functions, and variables.
*********************************************/

	/* - 1.1. - COLOURS, GRADIENTS, TYPOGRAPHY
	------------------------------------------ */
	
	@import "modules/_variables";


	/* - 1.2. - FUNCTIONS
	------------------------------------------ */

	@import "modules/_functions";
	
	
	/* - 1.3. - MIXINS
	------------------------------------------ */

	@import "modules/_mixins";
	
	
/*********************************************
	- 2.0 -  CSS RESET
*********************************************/

	/* - 2.1. - RESET INCLUDING HTML5
	------------------------------------------ */
	
	@import "partials/_reset";
	

/*********************************************
	- 3.0 -  SASS PARTIALS
*********************************************/

	/* - 3.1. - VENDOR
	------------------------------------------ */

	@import "partials/_vendor";
	
	
	/* - 3.2. - BASE ELEMENTS, STRUCTURE, TYPOGRAPHY, LINKS
	------------------------------------------ */
	
	@import "partials/_base";
	
	
	/* - 3.3. - BUTTONS 
	------------------------------------------ */
	
	@import "partials/_buttons";
	
	
	/* - 3.4. - FORMS 
	------------------------------------------ */
	
	@import "partials/_forms";


/*********************************************
	- 4.0 -  GRIDS
*********************************************/

	@import "partials/_grid";


/*********************************************
	- 5.0 -  ENTRY SECTION
*********************************************/
	
	.entry {
		background-size: cover;
		&--home {
			background-image: url(../img/banner-home.jpg);
			background-position: center center;
			text-align: center;
			width: 100%;
			@include breakpoint(medium) {
				height: 100%;
			}
			&:after {
				@include breakpoint(medium) {
					background: rgba(0,0,0,0.3);
					content: "";
					height: 100%;
					left: 0;
					position: absolute;
					top: 0;
					width: 100%;
				}
			}
			.entry__logo {
				display: block;
				margin: 0 auto 6em;
				max-width: 350px;
				width: 80%;
			}
			.btn--membr {
				font-size: 0.8em;
				font-weight: 900;
				margin: 0 auto;
				position: static;
				top: 0;
				z-index: 2;
				@include breakpoint(medium) {
					position: absolute;
					right: 0;
				}
			}
		}
		&__content-align {
			padding: 5em 0;
			@include breakpoint(medium) {
				padding: 0;
				position: relative;
				top: 50%;
				transform: translateY(-50%);
				z-index: 1;
			}
		}
		.nav-btn {
			right: 0.5em;
			position: fixed;
			top: 0.5em;
			z-index: 3;
			&__bar {
				background: $col-white;
			}
		}
	}


/*********************************************
	- 6.0 -  NAV
*********************************************/

	.nav {
		background: $col-link-bg;
		display: none;
		font-family: $header-font-family;
		height: 100%;
		letter-spacing: 0.1em;
		position: fixed;
		text-align: center;
		text-transform: uppercase;
		top: 0;
		width: 100%;
		z-index: 2;
		@include breakpoint(medium) {
			background: transparent;
			display: block;
			position: relative;
		}
		.menu-main_menu-container {
			height: 100%;
			@include breakpoint(medium) {
				height: auto;
			}
		}
		&__ul {
			position: relative;
			top: 50%;
			transform: translateY(-50%);
			@include breakpoint(medium) {
				top: 0;
				transform: none;
			}
		}
		&__li, li {
			font-size: 0.9em;
			margin: 1em 0;
			@include breakpoint(medium) {
				display: inline-block;
				font-size: 0.7em;
				margin: 0 0.4em;
			}
			@include breakpoint(medium-large) {
				font-size: 0.9em;
				margin: 0 0.7em;
			}
		}
		&__a, a {
			color: $col-black;
			&:hover {
				color: $col-black;
				//border-bottom: 2px solid $col-link;
			}
			@include breakpoint(medium) {
				color: $col-link;
			}
		}
		&--home {
			margin-bottom: 6em;
			.nav__a, a {
				@include breakpoint(medium) {
					color: $col-link-bg;
					&:hover {
						color: $col-white;
					}
				}
			}
			a, li {
				text-decoration: none;
			}
		}
		&--page {
			@include breakpoint(medium) {
				left: 3em;
				text-align: left;
				top: 18px;
				width: 860px;
			}
		}
	}


/*********************************************
	- 7.0 -  SECTION TEXT
*********************************************/

	.section {
		padding: 3em 0;
		@include breakpoint(medium) {
			padding: 6em 0;
		}
		&__large-text {
			margin: 0 auto 3em;
			max-width: 60%;
			@include breakpoint(medium) {
				font-size: 2em;
			}
		}
	}


/*********************************************
	- 8.0 -  QUICK LINKS
*********************************************/

	.quick-links {
		&__float {
			float: left;
			width: 100%;
			@include breakpoint(medium) {
				width: 50%;
			}
		}
		&__block {
			background-size: cover;
			overflow: hidden;
			position: relative;	
			&--small {
				height: 350px;
			}	
			&--large {
				height: 700px;
			}
			&:hover {
				span {
					transform: scale(1.2);
				}
			}
			span {
				background-image: url(../img/quick-info.jpg);
				background-position: center;
				background-size: cover;
				height: 100%;
				position: absolute;
				transform: scale(1);
				transition: all .5s;
				width: 100%;
			}
			&--info {

			}
			&--classes {
				span {
					background-image: url(../img/quick-classes.jpg);
				}
			}
			&--facilities {
				span {
					background-image: url(../img/quick-facilities.jpg);
				}
			}
			&--instructors {
				span {
					background-image: url(../img/quick-instructors.jpg);
				}
			}
		}
		&__title {
			color: $col-white;
			left: 1em;
			position: absolute;
			top: 1em;
		}
	}


/*********************************************
	- 9.0 -  LATEST
*********************************************/

	.latest {
		&__cat {
			font-family: $header-font-family;
			left: 0;
			padding: 1em;
			position: absolute;
			text-align: center;
			text-transform: uppercase;;
			top: 0;
			width: 100%;
		}
		&__block {
			padding: 3.5em 3em;
			position: relative;
			@include breakpoint(medium) {
				margin-bottom: -9999px;
				padding: 7em 6em 10079px;
			}
		}
		&__title {
			font-size: 3em;
			margin-bottom: 0em;
		}
		&__date {
			margin-bottom: 3em;
		}
	}


/*********************************************
	- 10.0 -  CHARITY
*********************************************/

	.charity {
		background-image: url(../img/banner-charity.jpg);
		background-position: center;
		background-size: cover;
		&__info {
			background: $col-white;
			margin: 10em auto;
			max-width: 650px;
			padding: 2em;
			text-align: center;
			width: 90%;
			@include breakpoint(medium) {
				padding: 5em;
			}
		}
		&__text {
			margin: 0 auto 2em;
			max-width: 90%;
		}
	}


/*********************************************
	- 11.0 -  FOOTER
*********************************************/

	.footer {
		background-color: $col-footer-bg;
		border-top: 15px solid #1b393e;
		font-family: $header-font-family;
		&__li, li {
			margin-bottom: 1em;
		}
		&__a, a {
			&:hover {
				border-bottom: 3px solid $col-white;
			}
		}
		&__a,
		p, 
		a, 
		h3,
		li {
			color: $col-white;
			font-size: 1em;
		}
		h3 {
			font-size: 1.1em;
		}
		.fa {
			color: #849092;
			margin-right: 1em;
		}
		&__logo {
			clear: both;
			display: block;
			margin: 3em auto;
			max-width: 150px;
			width: 50%;
		}
	}


/*********************************************
	- 12.0 -  HEADER
*********************************************/

	.nav-btn {
		cursor: pointer;
		&--active {
			.nav-btn__bar {
				background: $col-black;
			}
			.nav-btn__bar--one {
				top: 5px;
				transform: rotate(45deg);
			}
			.nav-btn__bar--two {
				display: none;
			}
			.nav-btn__bar--three {
				top: -2px;
				transform: rotate(-45deg);
			}
		}
		&__bar {
			background: $col-white;
			display: block;
			height: 3px;
			margin-bottom: 4px;
			position: relative;
			transition: all ease-in-out 0.2s;
			width: 21px;
		}
	}

	.header {
		padding: 0 0 1em;
		@include breakpoint(medium) {
			padding: 1em;
		}
		&--page {
			.btn--membr {
				font-size: 0.8em;
				font-weight: 900;
				margin: 0 auto;
				position: static;
				top: 0;
				z-index: 2;
				@include breakpoint(medium) {
					float: right;
					margin-top: 9px;
				}
			}
			.nav-btn {
				position: absolute;
				right: 0.5em;
				top: 0.5em;
				&__bar {
					background: $col-black;
				}
			}
		}
		&__logo {
			clear: both;
			display: block;
			margin: 1.5em auto;
			max-width: 40px;
			@include breakpoint(medium) {
				clear: none;
				float: left;
				margin: 0;
			}
		}
	}

	.social-icons {
		display: none;
		@include breakpoint(large) {
			display: block;
			float: right;
			margin: 0 5em 0 0;
			position: relative;
			top: -5px;
			z-index: 4;
		}
		&__li {
			display: inline;
			margin-left: 1em;
		}
	}


/*********************************************
	- 13.0 -  BANNER
*********************************************/

	.banner {
		background-color: $col-link-bg;
		background-size: cover;
		text-align: center;
		&--page {
			background-image: url(../img/banner-page.jpg);
		}
		&--instructors {
			background-image: url(../img/banner-instructors.jpg);
		}
		&--contact {
			background-image: url(../img/banner-contact.jpg);
		}
		&__head {
			color: $col-white;
			margin: 0 auto;
			max-width: 90%;
			padding: 3em 0;
			text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
			@include breakpoint(medium) {
				font-size: 5em;
				padding: 2em 0;
			}
		}
	}


/*********************************************
	- 14.0 -  PAGE CONTENT
*********************************************/

	.page-content {
		h2 {
			@include breakpoint(medium) {
				font-size: 3em;
			}
		}
		h3 {
			@include breakpoint(medium) {
				font-size: 1.5em;
			}
		}
		img {
			margin: 2em 0; 
			width: 100%;
		}
		p {
			font-size: 1.1em;
			@include breakpoint(medium) {
				font-size: 1.3em;
				line-height: 1.6;
			}
			a:not(.btn) {
				text-decoration: underline;
			}
		}
		a {
			&:hover {
				background: $col-link-bg;
				color: $col-black;
			}
		}
	}

	.tabs {
		clear: both;
		margin-top: 5em;
		&__li {
			background: #f4f4f4;
			border-bottom: 1px solid #e2e1e1;
			border-right: 1px solid #e2e1e1;
			border-top: 1px solid #e2e1e1;
			cursor: pointer;
			display: inline-block;
			font-family: $header-font-family;
			font-weight: $weight-bold;
			font-size: 0.8em;
			padding: 0.8em 0.5em;
			text-align: center;
			text-transform: uppercase;
			width: 25%;
			@include breakpoint(medium) {
				width: 14.2857143%;
			}
			@include breakpoint(large) {
				font-size: 1em;
				padding: 1em 1.5em;
			}
			&:first-child {
				border-left: 1px solid #e2e1e1;
			}
			&:hover,
			&--active {
				background: $col-link-bg;
			}
		}
	}

	.tab-content {
		border-bottom: 1px solid #e2e1e1;
		border-left: 1px solid #e2e1e1;
		border-right: 1px solid #e2e1e1;
		display: none;
		font-size: 0.8em;
		overflow: hidden;
		padding: 1.5em;
		@include breakpoint(medium) {
			font-size: 1em;
			padding: 3em;
		}
		&--active {
			display: block;
		}
		h3 {
			font-size: 1.3em;
		}
		h2 {
			margin-bottom: 0;
		}
		p {
			@include breakpoint(medium) {
				font-size: 1.3em;
				line-height: 1.6;
			}	
		}
	}


/*********************************************
	- 15.0 -  SIDEBAR
*********************************************/

	.sidebar {
		padding: 0;
		&__module {
			padding: 2.5em;
			@include breakpoint(medium) {
				padding: 5em;
			}
			img {
				margin-bottom: 1em;
			}
		}
		&__split-li {
			float: left;
			padding: 2em;
			width: 50%;
			img {
				max-width: 100%;
			}
		}
		.menu-all_pages-container {
			li {
			    list-style: circle;
			    margin-left: 1em;
			    margin-bottom: 1em;
			}
			a {
				&:hover {
					background: $col-link-bg;
					color: $col-black;
				}
			}
		}
	}


/*********************************************
	- 16.0 -  CAT
*********************************************/

	.cat {
		display: flex;
		font-size: 0;
		flex-wrap: wrap;
		overflow: hidden;
		padding: 0;
		&__block {
			border-bottom: 1px solid $col-border;
			border-right: 1px solid $col-border;
			display: inline-block;
			padding: 2em;
			width: 100%;
			@include breakpoint(medium) {
				padding: 3em;
				width: 50%;
			}
			@include breakpoint(large) {
				width: 25%;
			}
		}
		&__head {
			font-size: 1.3em;
			margin-bottom: 0.5em;
		}
		&__name {
			font-size: 1.3em;
			margin-bottom: 2em;
		}
		&__desc {
			font-size: 0.9em;
		}
		&__avatar {
			border-radius: 50%;
			margin-bottom: 2em;
			max-width: 150px;
		}
		.date,
		&__sub {
			font-size: 0.7em;
			margin-bottom: 2em;
		}
		.btn {
			font-size: 0.7em;
		}
	}


/*********************************************
	- 17.0 -  PROMOTIONS
*********************************************/

	.promotion {
		background-image: url(../img/banner-charity.jpg);
		background-position: center;
		background-size: cover;
		&__info {
			background: rgba(255,255,255,0.9);
			margin: 10em auto;
			max-width: 650px;
			padding: 2em;
			text-align: center;
			width: 90%;
			@include breakpoint(medium) {
				padding: 5em;
			}
		}
		&__text {
			margin: 0 auto 2em;
			max-width: 90%;
		}
	}


	.example {
		color: red;
		@include breakpoint(medium) {
			color: yellow;
		}
		@include breakpoint(large) {
			color: green;
		}
		@include breakpoint(retina) {
			background: url(logo2x.jpg) no-repeat;
			background-size: 212px 303px;
		}
	}
	
	/* BASE TEMPLATE STYLES END */


/*********************************************
	- 0.0 -  @FONT-FACE
*********************************************/
	
	@import "partials/_font-face";
	




		

















