/* - BASE HTML TEMPLATE
------------------------------------------------- 
	Description: Forms
	Author: Shane Prendergast
	Author URL: http://www.webknit.co.uk
	Template URL: http://base.webknit.co.uk/
*/


/* - CONTENTS
-------------------------------------------------
	- Forms ................................ 1.0.
		- Webkit reset ..................... 1.1.
		- Base Elements .................... 1.2.
	- Example Form ......................... 2.0.
*/


/*********************************************
	- 1.0 -  FORMS
*********************************************/

	/* - 1.1. - WEBKIT RESET
	------------------------------------------ */

	input[type=text],
	input[type=email],
	input[type=tel],
	input[type=url],
	input[type=search],
	input[type=submit] {
		appearance: none;
		border-radius: 0;
	}

	/* - 1.2. - BASE ELEMENTS
	------------------------------------------ */

	input, 
	textarea {
		background: #f4f4f4;
		border: none;
		border-radius: 0.125em;
		color: $col-black;
		display: block;
		font-family: $base-font-family;
		font-size: $base-font-size;
		margin: 0;
		margin-bottom: 1.250em;
		outline: none;
		padding: 1em;
		width: 100%;
	}
	
	.input[type=email] {
		
	}
	
	input {
		max-width: 50%;
	}
	
	textarea {
		min-height: 300px;
		//max-width: 25.000em;
	}
	
	input[type=submit] {
		display: inline-block;
		width: auto;
	}
	

/*********************************************
	- 2.0 -  EXAMPLE FORM
*********************************************/