/* - BASE HTML TEMPLATE
------------------------------------------------- 
	Description: Mixins
	Author: Shane Prendergast
	Author URL: http://www.webknit.co.uk
	Template URL: http://base.webknit.co.uk/
*/


/* - CONTENTS
-------------------------------------------------
	- Mixins  .............................. 1.0.
	- SVG BG-img Fallback  ................. 2.0.
*/


/*********************************************
	- 1.0 -  BREAKPOINT
*********************************************/

	@mixin breakpoint($point) {
		@if $point == medium {
			@media only screen and (min-width: 50em) { @content; }
		}
		@else if $point == medium-large {
			@media only screen and (min-width: 56.250em) { @content; }
		}
		@else if $point == large {
			@media only screen and (min-width: 73.125em) { @content; }
		}
		@else if $point == retina {
			@media print, (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) { @content; }
		}
	}
	
	
/*********************************************
	- 2.0 -  SVG BG-IMG FALLBACK
*********************************************/
	
	// Sass mixin for SVG with fallback support using the invisible gradient technique
	// http://pauginer.com/post/36614680636/invisible-gradient-technique
	@mixin vector-image($img, $fallback, $position: center center, $size: auto) {
		background: transparent url($fallback) $position no-repeat;
		background-image: -webkit-linear-gradient(transparent, transparent), url($img);
		background-image: linear-gradient(transparent, transparent), url($img);
		background-size: $size;
	}
