/* - BASE HTML TEMPLATE
------------------------------------------------- 
	Description: Buttons
	Author: Shane Prendergast
	Author URL: http://www.webknit.co.uk
	Template URL: http://base.webknit.co.uk/
*/


/* - CONTENTS
-------------------------------------------------
	- Buttons .............................. 1.0.
*/


/*********************************************
	- 1.0 -  BUTTONS
*********************************************/

	a.button {
		background: $col-link;
		border-radius: 0.188em;
	    color: $col-white;
	    display: inline-block;
	    float: left;
	    margin-bottom: 0.625em;
	    outline: 0 none;
	    padding: 0.438em 0.938em;
	    width: auto;
	    &:hover {
	    	background: $col-link-hover;
			color: #ffffff;
			text-decoration: none;
		}
		&:active {
			opacity:0.9;
		}
	}
	
	a.button-full-width {
		padding-left: 0 !important;
		padding-right: 0 !important;
		text-align: center; 
		width: 100%;
	}

	.btn,
	input[type=submit] {
		background: $col-link-bg;
		color: $col-black;
		display: inline-block;
		font-family: $header-font-family;
		font-size: 0.8em;
		letter-spacing: 0.1em;
		padding: 1em;
		text-transform: uppercase;
		@include breakpoint(medium) {
			font-size: 0.9em;
		}
		&:hover {
			background: $col-black;
			color: $col-white;
			transition: all ease 0.2s;
		}
		.fa {
			margin-right: 1em;
		}
	}

	.nav-btn {
		color: $col-white;
		cursor: pointer;
		display: block;
		font-size: 1.5em;
		z-index: 3;
		@include breakpoint(medium) {
			display: none;
		}
	}
	
	/* Fix for odd Mozilla border & padding issues */
	button::-moz-focus-inner,
	input::-moz-focus-inner {
	    border: 0;
	    padding: 0;
	}
